<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <div
        v-if="loaderData"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Closing Soon Product Percentage"
            rules="required"
          >
            <b-form-group
              label="Closing Soon Product Percentage"
              label-for="Closing Soon Product Percentage"
            >
              <b-form-input
                id="Closing Soon Product Percentage"
                v-model="AppVersionForm.closing_soon_product_percentage"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Closing Soon Product Percentage"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Max Price To Cancel Order -->
          <validation-provider
            #default="validationContext"
            name="Max Price To Cancel Order"
            rules="required"
          >
            <b-form-group
              label="Max Price To Cancel Order"
              label-for="Max Price To Cancel Order"
            >
              <b-form-input
                id="Max Price To Cancel Order"
                v-model="AppVersionForm.max_price_cancel_order"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Max Price To Cancel Order"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6"><b-form-group
          v-slot="{ ariaDescribedby }"
          label="Payment Method:"
        >
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="AppVersionForm.support_payments"
            :aria-describedby="ariaDescribedby"
            name="flavour-2"
          >
            <b-form-checkbox
              value="3"
              :checked="AppVersionForm.support_payments.includes('3')"
            >
              Paytap
            </b-form-checkbox>
            <b-form-checkbox value="2">
              Wallet
            </b-form-checkbox>

          </b-form-checkbox-group>
        </b-form-group></b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addAppVersion()"
          >
            Update
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
  },
  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      indeterminate: false,
      countries: [],
      users: [],
      loader: false,
      loaderData: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const AppVersion_images = ref([])

    const AppVersionForm = ref({

    })
    return {
      getValidationState,
      AppVersionForm,
      AppVersion_images,
    }
  },
  mounted() {
    this.showAppVersion()
    this.getCountries()
  },
  methods: {

    showAppVersion() {
      this.loaderData = true

      axios.get('show_app_data').then(res => {
        if (res.status === 200) {
          this.AppVersionForm = res.data.data.app_data
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        // this.formData = new FormData()
      }).finally(() => {
        this.loaderData = false
      })
    },
    addAppVersion() {
      this.loader = true
      const formData = new FormData()

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.AppVersionForm) {
        if (key !== 'support_payments') {
          formData.append(key, this.AppVersionForm[key])
        } else {
          let supportArray = Array.from(this.AppVersionForm[key])
          supportArray = supportArray.filter(item => item)
          supportArray.forEach(element => {
            formData.append('support_payments[]', element)
          })
        }
      }

      axios.post('update_app_data', formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.$router.push({ name: 'app-data' })
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Somthing Went Wrong',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}

</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  </style>
